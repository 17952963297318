.feature-new-section {
    padding: 140px 0 60px 0;
    background: #F4FAFE;
    min-height: 858px;

    .feature-heading-content {
        padding-bottom: 40px;
        h2 {
            text-align: center;
            font-size: 40px;
            font-weight: 150%;
            color: #040405;
            margin: 0;
            font-weight: 700;
        }
    }

    .feature-card {
        width: 100%;
        height: 540px;
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        margin-bottom: 30px;
        background-color: #fff;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left top;
        }
        .feature-card-content {
            padding: 24px;
            border-radius: 16px 0 0 0;
            background: linear-gradient(283deg, #0086E6 5.56%, #8DD0FF 113.66%);
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            max-width: 75%;
            h3 {
                font-size: 20px;
                line-height: 150%;
                font-weight: 600;
                color: #fff;
                margin-bottom: 12px;
            }
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                li {
                    list-style: none;
                    padding-right: 28px;
                    font-size: 14px;
                    line-height: 150%;
                    font-weight: 400;
                    color: #fff;
                    position: relative;
                    &:last-child {
                        padding-right: 0;
                    }
                    &::before {
                        content: "";
                        width: 8px;
                        height: 8px;
                        background-color: #fff;
                        border-radius: 100px;
                        position: absolute;
                        left: -16px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                    &:first-child {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        .feature-top-content {
            background: unset;
            top: 0;
            left: 0;
            bottom: unset;
            right: unset;
            max-width: 100%;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
    .feature-card-right {
        height: 254px;
        .feature-card-content {
            padding: 16px;
            border-radius: 16px 0 16px 0;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            max-width: 340px;
            border: 2px solid rgba(0, 134, 230, 0.20);
            background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
            max-width: 90%;
            h3 {
                font-size: 14px;
                line-height: 150%;
                font-weight: 600;
                color: #040405;
                margin-bottom: 0;
            }
        }
    }
}

.contact-dfeature-new-section {
    padding: 60px 0 110px 0;
}

// technology css
.technology-feature-section {
    padding-bottom: 0;
}

@media only screen and (max-width: 1400px) and (min-width: 1025px) {
    .container {
        max-width: 1000px !important;
    }
    .feature-new-section {
        min-height: 620px !important;
        padding-bottom: 0 !important;
    }
    .feature-new-section .feature-card .feature-card-content {
        padding: 16px;
    }
    .feature-new-section .feature-card .feature-card-content h3 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 8px;
    }
    .feature-new-section .feature-card .feature-card-content ul li {
        font-size: 12px;
        line-height: 16px;
    }
    .feature-new-section .feature-card-right .feature-card-content {
        padding: 14px;
    }
    .feature-new-section .feature-card-right .feature-card-content h3 {
        font-size: 12px;
        margin: 0;
    }
}

@media (max-width: 1024px) {
    .technology-feature-section {
        min-height: 620px !important;
        padding-bottom: 0 !important;
    }
    .feature-new-section {
        padding: 80px 0 20px 0;
        min-height: auto;
    }
    .contact-dfeature-new-section {
        padding: 50px 0 50px 0;
    }
    .feature-new-section .feature-heading-content {
        padding-bottom: 32px;
    }
    .feature-new-section .feature-heading-content h2 {
        font-size: 32px;
    }

    .feature-new-section .feature-card .feature-card-content {
        padding: 16px;
        max-width: 94%;
    }
    .feature-new-section .feature-card .feature-card-content h3 {
        font-size: 16px;
        margin-bottom: 8px;
    }
    .feature-new-section .feature-card-right .feature-card-content {
        padding: 12px;
    }
    .feature-new-section .feature-card-right .feature-card-content h3 {
        margin: 0;
    }
    .feature-new-section .feature-card .feature-card-content ul li {
        font-size: 12px;
    }
    .feature-new-section .feature-card-right .feature-card-content h3 {
        font-size: 14px;
    }

    .technology-feature-section {
        padding-bottom: 0;
    }
    .feature-new-section .feature-card .feature-card-content {
        padding: 16px;
    }
    .feature-new-section .feature-card .feature-card-content h3 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 8px;
    }
    .feature-new-section .feature-card .feature-card-content ul li {
        font-size: 12px;
        line-height: 16px;
    }
    .feature-new-section .feature-card-right .feature-card-content {
        padding: 14px;
    }
    .feature-new-section .feature-card-right .feature-card-content h3 {
        font-size: 12px;
        margin: 0;
    }
}

@media (max-width: 992px) {
    .feature-new-section {
        padding: 60px 0;
    }
    .feature-new-section .feature-heading-content {
        padding-bottom: 24px;
    }
    .feature-new-section .feature-heading-content h2 {
        font-size: 24px;
    }
    .feature-new-section .feature-card {
        height: 416px;
        margin-bottom: 16px;
    }
    .feature-new-section .feature-card .feature-card-content {
        padding: 14px;
    }
    .feature-new-section .feature-card .feature-card-content h3 {
        font-size: 16px;
        margin-bottom: 8px;
    }
    .feature-new-section .feature-card-right .feature-card-content {
        padding: 12px;
    }
    .feature-new-section .feature-card-right .feature-card-content h3 {
        margin: 0;
    }
    .feature-new-section .feature-card .feature-card-content ul li {
        font-size: 12px;
        padding-right: 18px;
    }
    .technology-feature-section {
        padding-bottom: 0;
    }
    .feature-new-section .feature-card-right .feature-card-content h3 {
        font-size: 12px;
        margin: 0;
    }
    .feature-new-section .feature-card .feature-card-content ul li::before {
        left: -13px;
        width: 5px;
        height: 5px;
    }
}

@media (max-width: 767px) {
    .feature-new-section .feature-card .feature-card-content {
        max-width: 84%;
    }
    .feature-new-section .feature-card-right .feature-card-content {
        max-width: 90%;
    }
    .feature-new-section .feature-card .feature-card-content ul li::before {
        width: 6px;
        height: 6px;
        left: -12px;
    }
    .contact-dfeature-new-section {
        padding: 40px 0;
    }
}
